<template >
  <div>
    <block-title>退貨明細</block-title>

    <order-back-card
        v-for="(item, index) in backItems"
        :key="index"
        :value="item"
        :configs="cardConfigs(item)"
    ></order-back-card>

    <block-title :hasBorder="false">圖片上傳</block-title>
    <photo-selector
      :value="photos"
      multiple
      class="mb-5"
      readonly
    ></photo-selector>

    <v-textarea
      v-model="comment"
      disabled
      name="comment"
      outlined
      class="mb-5"
      label="備註"
      auto-grow
    ></v-textarea>

    <number-bar
      title="退款合計"
      unit="元"
      :value="total"
      readonly
      type="secondary"
      class="mb-5"
    ></number-bar>

    <bar class="mb-5" :type="statusBarType">
      <template v-slot:label> 退貨狀態 </template>
      <template v-slot:value>{{ backOrderStatusText }}</template>
    </bar>
  </div>
</template>

<script lang="babel" type="text/babel">
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import backOrderItemsMixins from "@/mixins/backOrderItems.js"

export default {
  mixins: [ItemMixin, backOrderItemsMixins],
  data: () => ({

  }),
  computed: {
    backOrderStatusText() {
      return this.props.backOrderStatusText
    },
    status() {
      return this.propsValue.status
    },
    backOrderStatus() {
      return this.backOrder.backOrder_status
    },
    statusBarType() {
      if(this.status == 'finished' && this.backOrderStatus == 'SUCCESS') return 'success'
      if(this.status == 'canceled' && this.backOrderStatus == 'SUCCESS') return 'success'
      if(this.backOrderStatus == 'REJECT') return 'error'
      return 'primary'
    },
    backOrder() {
      return this.propsValue.backOrder
    },
    backItems() {
      if(!this.propsValue) return []
      return this.propsValue.backItems.map((item, index) => {
        // console.log(item)
        // console.log(this.items)
        const reasonTarget = this.propsValue.backReason.find(reason => reason.value == item.reason)
        return {
          ...item,
          return_count: this.$helper.reverseShippingCount(item.count,item.shipping_unit),
          price: this.items[index].price,
          reason: reasonTarget.text,
          discount_config: this.items[index].discount_config,
          shipping: this.items[index].shipping,
          // amount: this.items[index].amount
        }
      })
    },
    photos() {
      if(!this.backOrder) return []
      return this.backOrder.received_record
    },
    comment() {
      if(!this.backOrder) return null
      return this.backOrder.comment
    },
    // 退款合計
    total() {
      if(!this.backOrder) return 0
      return Math.round(this.backOrder.total)
    },
  },
  watch : {

  },
  methods: {
    cardConfigs(item) {
      return [
        {label: item.product, labelColor: "primary--text", colon: false},
        { id: "unit", col: 6 },
        {
          id: "price",
          valueSuffix: "元",
          col: 6
        }, 
        { 
          id: "return_count", 
          label: "退貨數量",  
        },
        {
          label: '退貨原因',
          value: item.reason
        },
        {
          id: "total",
          label: "退款小計",
          type: "total",
          valueSuffix: "元",
          barType: 'gray',
          value: item.amount
        },
      ]
    },
  },
}
</script>

